import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import { FaFacebookF, FaInstagram, FaWhatsapp } from "react-icons/fa";
import Container from "@material-ui/core/Container";
import { TimelineLite, Power3 } from "gsap/dist/gsap";
const Wrapper = styled.div`
  position: relative;
  padding-top: 60px;
  z-index: -1;
  height: 20vh;
`;
const BelowHero = () => {
  let content = useRef(null);
  let tl = new TimelineLite();

  useEffect(() => {
    const contentButton = content.children[0];
    tl.staggerFrom(
      [contentButton],
      1,
      {
        y: 0,
        ease: Power3.easeOut,
        delay: 0.8,
      },
      0.15
    ).from(contentButton, { y: 120, opacity: 0, ease: Power3.easeOut }, 1.4);
  }, []);

  return (
    <Wrapper>
      <Container ref={(el) => (content = el)}>
        <div>
          <IconButton
            component="a"
            target="_blank"
            href="https://www.facebook.com/ok-101283391453374/"
            style={{
              background: "rgb(207, 235, 216)",
              color: "#000",
              marginRight: 15,
            }}
          >
            <FaFacebookF />
          </IconButton>
          <IconButton
            component="a"
            target="_blank"
            href="https://www.instagram.com/okzh.ch/"
            style={{
              background: "rgb(207, 235, 216)",
              color: "#000",
              marginRight: 15,
            }}
          >
            <FaInstagram />
          </IconButton>
          <IconButton
            component="a"
            target="_blank"
            href="https://wa.me/41766133635"
            style={{
              background: "rgb(207, 235, 216)",
              color: "#000",
              marginRight: 15,
            }}
          >
            <FaWhatsapp />
          </IconButton>
        </div>
      </Container>
    </Wrapper>
  );
};

export default BelowHero;
