import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";

const Nav = styled.nav`
  span {
    margin-right: 25px;
    text-decoration: none;
    text-transform: uppercase;
    color: #000;
    display: inline-block;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 960px) {
    text-align: center;

    span {
      margin-right: 15px;
    }
  }
`;

const Rights = styled(Grid)`
  text-align: right;

  @media (max-width: 960px) {
    text-align: center;
  }
`;

const Footer = (props) => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer
      style={{
        background: "#fff",
        width: "100%",
        position: "relative",
        paddingTop: 0,
      }}>
      <Container style={{ paddingBottom: 30 }}>
        <Divider />
        <Grid container spacing={2} style={{ paddingTop: 30 }}>
          <Grid item xs={12} md={6}>
            <Nav className='nav'>
              <span className='home'>Home</span>
              <span className='about'>Über uns</span>
              <span className='menu'>Menü</span>
              <span className='time'>Öffnungszeiten</span>
              <span className='locations'>Standort</span>
            </Nav>
          </Grid>
          <Rights item xs={12} md={6}>
            Copyright© o'k, {year}. All rights reserved
          </Rights>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
