import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { FiArrowRight } from "react-icons/fi";

const DirectionWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 960px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const Directions = styled.span`
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 12px 32px;

  svg {
    height: 18px;
    width: 18px;
    margin-left: -15px;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s ease;
  }

  &:hover {
    svg {
      margin-left: 15px;
      opacity: 1;
      visibility: visible;
    }
  }
`;

const Form = styled.div`
  min-width: 400px;
  .email {
    border: 1px solid #ccc;
    border-radius: 50px;
    padding: 12px 22px;
    outline: none !important;
    width: 100%;
    margin-bottom: 20px;
    height: 48px;
    font-size: 16px;
  }
  .button {
    margin-bottom: 15px;
    width: 100%;
  }
`;

export default function AlertDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <DirectionWrapper item xs={12} md={5}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          style={{
            backgroundColor: "#4285f4",
            padding: 0,
            minWidth: 220,
          }}
          component="span"
        >
          <Directions>
            Notify me <FiArrowRight />
          </Directions>
        </Button>
      </DirectionWrapper>

      <Dialog open={open} onClose={handleClose} style={{ zIndex: 9999999 }}>
        <DialogTitle id="alert-dialog-title">
          Subscribe to our newsletter
        </DialogTitle>
        <DialogContent>
          <Form>
            <div id="mc_embed_signup">
              <form
                action="https://two.us4.list-manage.com/subscribe/post?u=6a4a0f87b131bc01a5cd17460&amp;id=308a85d993"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                noValidate
              >
                <div id="mc_embed_signup_scroll">
                  <input
                    type="email"
                    name="EMAIL"
                    className="email"
                    id="mce-EMAIL"
                    placeholder="Email"
                    required
                  />
                  <div
                    style={{ position: "absolute", left: "-5000px" }}
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_6a4a0f87b131bc01a5cd17460_308a85d993"
                      tabIndex="-1"
                    />
                  </div>
                  <div className="clear">
                    <input
                      type="submit"
                      value="Subscribe"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="button MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary"
                    />
                  </div>
                </div>
              </form>
            </div>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
