import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { TimelineLite, Power3 } from "gsap/dist/gsap";
import donner from "../../images/doner.png";
import $ from "jquery";
const DonnerWrapper = styled.div`
  position: relative;
  z-index: 120;
  transition: 0.5s ease;
  opacity: 1;
  &.hidden {
    z-index: 0;
    opacity: 0;
  }
  .donner {
    width: 50%;
    position: fixed;
    right: 0;
    top: 140px;
    img {
      width: 500px;
    }
  }

  @media (max-width: 1600px) {
    img {
      width: 450px !important;
    }
  }

  @media (max-width: 960px) {
    display: none !important;
    z-index: 10;
    .donner {
      width: 50%;
      position: fixed;
      left: 15vw;
      bottom: 25%;
      top: auto !important;
      img {
        width: 70vw !important;
      }
    }
  }
`;
const Donner = () => {
  let content = useRef(null);

  let tl = new TimelineLite();

  useEffect(() => {
    const donnerWrapper = content.children[0];

    //Content Animation
    tl.staggerFrom(
      [donnerWrapper],
      1,
      {
        y: 120,
        ease: Power3.easeOut,
      },
      0.15
    ).from(
      donnerWrapper,
      1,
      { x: 500, opacity: 0, rotation: 90, scale: 0.5, ease: Power3.easeOut },
      1
    );
  }, []);

  useEffect(() => {
    $(window).scroll(function () {
      var theta = $(window).scrollTop() / 10;
      $(".donner img").css({
        transform: "rotate(" + theta + "deg)",
      });
      $(".donner").css({
        top: theta * 1.5 + 140,
      });
      $(".donner").css({
        top: theta * 1.5 + 140,
      });
    });
  });
  useEffect(() => {
    var theta = $(window).scrollTop() / 10;
    $(".donner").css({
      top: theta * 1.5 + 140,
    });
  }, []);

  return (
    <DonnerWrapper ref={(el) => (content = el)} className="donner-wrapper">
      <div className="donner">
        <img src={donner} alt="" />
      </div>
    </DonnerWrapper>
  );
};

export default Donner;
