import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import { FiArrowDown } from "react-icons/fi";
import $ from "jquery";
import {
  TimelineLite,
  Power3,
  TweenLite,
  fixer,
  TweenMax,
  Quad,
} from "gsap/dist/gsap";
import Container from "@material-ui/core/Container";
import carrot from "../../images/carrot.png";
import tomato from "../../images/tomato.png";
import tomato2 from "../../images/tomato2.png";
import rosemary from "../../images/rosemary.png";
import mint from "../../images/mint.png";
import Donner from "./DonnerHero";
const Hero = styled.div`
  height: 80%;
  position: relative;
  padding-top: 120px;
  transition: 0.5s ease;

  .background {
    background-color: rgb(207, 235, 216);
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0;
  }

  h1 {
    font-weight: 900;
    font-size: 80px;
    margin-bottom: 24px;
    margin: 0;
    width: 50%;
    padding-top: 80px;
    .hero-content-line {
      margin: 0;
      height: 100px;
      overflow: hidden;
    }
  }
  h3 {
    z-index: 9999;
    font-size: 30px;
  }
  #scroll-to {
    animation-name: bounce-1;
    animation-timing-function: linear;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  @keyframes bounce-1 {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }

  .carrot {
    position: absolute;
    top: 30%;
    right: -200px;
  }
  .tomato {
    position: absolute;
    top: 50%;
    left: -150px;
    width: 300px;
  }
  .tomato2 {
    position: absolute;
    top: 75%;
    left: 50%;
    width: 500px;
  }
  .mint {
    position: absolute;
    top: -100px;
    right: -100px;
    width: 300px;
  }

  .rosemary {
    position: absolute;
    top: -200px;
    left: -150px;
    width: 500px;
  }

  .donner {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    z-index: 9999;
    position: absolute;
    position: relative;
    img {
      width: 550px;
    }
  }

  @media (max-width: 1600px) {
    padding-top: 100px;
    .mint,
    .carrot,
    .rosemary,
    .tomato,
    .tomato2 {
      transform: scale(0.8);
    }
  }

  @media (max-width: 960px) {
    max-height: 570px;
    .hero-items {
      display: flex;
      flex-direction: column;
    }
    .donner {
      display: block !important;
    }
    h1 {
      width: 100%;
      font-size: 50px;
      text-align: center;
      padding-top: 0px;
      margin-top: -30px;
      .hero-content-line {
        margin: 0;
        height: 55px;
        overflow: hidden;
      }
    }
    h3 {
      font-size: 20px;
      text-align: center;
    }
    .mint {
      top: 20%;
    }
    .carrot {
      display: none;
    }
    .tomato {
      top: 30%;
    }
  }

  @media (max-height: 700px) {
    max-height: 500px;

    .donner {
      img {
        width: 60vw !important;
        margin-top: -30px;
      }
    }
  }

  @media (max-height: 600px) {
    max-height: 400px;

    h1 {
      width: 100%;
      font-size: 30px;
      text-align: center;
      padding-top: 0px;
      margin-top: -40px;
      .hero-content-line {
        margin: 0;
        height: 35px;
        overflow: hidden;
      }
    }

    .donner {
      img {
        width: 60vw !important;
        margin-top: -40px;
      }
    }
  }
`;
const HomeHero = () => {
  let content = useRef(null);
  let images = useRef(null);
  let hero = useRef(null);
  let tl = new TimelineLite();

  useEffect(() => {
    const background = hero.children[0];
    //Content Animation
    tl.staggerFrom(
      [background],
      1,
      {
        y: 0,
        opacity: 1,
        ease: Power3.easeOut,
      },
      0.15
    ).from(background, 1, { y: -2000, opacity: 0, ease: Power3.easeOut }, 0.6);

    TweenMax.from($(".donner > img"), 0.5, {
      css: { scale: 0.05, opacity: 0, rotation: 180 },
      ease: Quad.easeInOut,
    });
  }, []);

  useEffect(() => {
    const headlineFirst = content.children[0].children[0];
    const headlineSecond = headlineFirst.nextSibling;
    const headlineThird = headlineSecond.nextSibling;
    const contentP = content.children[1];
    const contentButton = content.children[2];

    //Content Animation
    tl.staggerFrom(
      [headlineFirst.children, headlineSecond.children, headlineThird.children],
      1,
      {
        y: 120,
        ease: Power3.easeOut,
      },
      0.15
    )
      .from(contentButton, 1, { y: 120, opacity: 0, ease: Power3.easeOut }, 1.6)
      .from(contentP, 1, { y: 120, opacity: 0, ease: Power3.easeOut }, 1.6);
  }, []);

  useEffect(() => {
    const carrotImage = images.children[0];
    const tomatoImage = images.children[1];
    const tomato2Image = images.children[2];
    const radishImage = images.children[3];
    const rosemaryImage = images.children[4];
    const mintImage = images.children[5];

    tl.staggerFrom(
      [
        carrotImage,
        tomatoImage,
        tomato2Image,
        radishImage,
        rosemaryImage,
        mintImage,
      ],
      1,
      {
        x: 0,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.8,
      },
      0.15
    )
      .from(carrotImage, 1, { x: 300, opacity: 0, ease: Power3.easeOut }, 1.4)
      .from(tomatoImage, 1, { x: -300, opacity: 0, ease: Power3.easeOut }, 1.4)
      .from(tomato2Image, 1, { y: 300, opacity: 0, ease: Power3.easeOut }, 1.4)
      .from(
        radishImage,
        1,
        { x: 300, y: -300, opacity: 0, ease: Power3.easeOut },
        1.4
      )
      .from(
        rosemaryImage,
        1,
        { x: -300, y: -300, opacity: 0, ease: Power3.easeOut },
        1.4
      )
      .from(mintImage, 1, { y: 300, opacity: 0, ease: Power3.easeOut }, 1.4);
  }, []);

  useEffect(() => {
    $("#scroll-to").click(function (e) {
      e.preventDefault();
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#about").offset().top - 60,
        },
        500
      );
    });
  });

  return (
    <Hero ref={(el) => (hero = el)}>
      <div className='background'></div>
      <div ref={(el) => (images = el)} className='images-container'>
        <img src={carrot} className='carrot parallax' />
        <img src={tomato} className='tomato parallax' />
        <img src={tomato2} className='tomato2 parallax' />
        <img src={mint} className='mint parallax' />
        <img src={rosemary} className='rosemary parallax' />
      </div>

      <Container>
        <div
          ref={(el) => (content = el)}
          style={{ display: "flex", flexDirection: "column" }}
          className='hero-items'>
          <h1>
            <div className='hero-content-line'>
              <div className='hero-content-line-inner'>THE</div>
            </div>
            <div className='hero-content-line'>
              <div className='hero-content-line-inner'>SPECIAL</div>
            </div>
            <div className='hero-content-line'>
              <div className='hero-content-line-inner'>ONE</div>
            </div>
          </h1>

          <h3>
            NEU! Vorbestellung möglich
            <br />
            Telefonisch: 076 613 36 35
          </h3>

          <Donner />

          <IconButton
            id='scroll-to'
            style={{
              background: "#000",
              color: "#fff",
              position: "absolute",
              bottom: -20,
            }}>
            <FiArrowDown />
          </IconButton>
        </div>
      </Container>
    </Hero>
  );
};

export default HomeHero;
