import React, { useRef, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Fade from "react-reveal/Fade";
import styled from "styled-components";
import meet from "../../images/meet.jpg";

const CardWrapper = styled.div`
  margin-bottom: 30px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  svg {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }
  h3 {
    margin: 0;
    font-size: 25px;
  }
  p {
    margin: 0;
    font-size: 18px;
    max-width: 400px;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    margin-left: 10px;
  }

  h3 {
    text-align: center;
  }

  @media (max-width: 960px) {
    text-align: center;
    align-items: center;
    justify-content: center;

    h3 {
      span {
        display: block;
      }
    }
  }
`;

function Card({ title, description, bracked }) {
  return (
    <CardWrapper>
      <Fade bottom duration={1300}>
        <div style={{ textAlign: "center" }}>
          <h3>
            {title}
            {bracked && <span>({bracked})</span>}
          </h3>
          <p>{description}</p>
        </div>
      </Fade>
    </CardWrapper>
  );
}

export default function Menu() {
  return (
    <div style={{ backgroundColor: "rgb(207, 235, 216)" }}>
      <Container
        style={{
          maxWidth: 800,
          paddingTop: 80,
          paddingBottom: 140,
          marginTop: -20,
        }}>
        <Fade bottom duration={1300}>
          <h1
            style={{
              fontSize: 60,
              textAlign: "center",
              margin: 0,
              marginBottom: 30,
            }}>
            Unser Angebot
          </h1>
        </Fade>
        <Fade bottom duration={1300}>
          <p
            style={{
              fontSize: 18,
              textAlign: "center",
              margin: 0,
              marginBottom: 80,
            }}>
            Beste Schweizer-Produkte und ein leckeres Rezept mit frischem Gemüse
            bringen ein neues Geschmackserlebnis. Jetzt probieren!
          </p>
        </Fade>

        <Card
          title='Gemüsedöner'
          description='mit Pouletfleisch, Grillgemüse & Salat'
          bracked='Taschenbrot'
        />
        <Card
          title='Dürüm'
          description='mit Pouletfleisch, Grillgemüse & Salat'
          bracked='Fladenbrot'
        />
        <Card title='Dönerteller' />
        <Card
          title='Gemüsedöner Vegan'
          description='PLANTED.CHICKEN, Grillgemüse & Salat'
          bracked='Taschenbrot'
        />
        <Card
          title='Dürüm Vegan'
          description='PLANTED.CHICKEN, Grillgemüse & Salat'
          bracked='Fladenbrot'
        />
      </Container>
    </div>
  );
}
